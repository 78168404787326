<template>
  <div class="withdraw">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 16px;line-height: normal;">提幣</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div>
      <div style="width: 100%;font-size: 0.5rem;margin-left: 10%;">
       當前餘額: {{ userbalance }}
      </div>

    </div>
    <div style="padding: 20px;" class="Recharge">
      <van-cell-group inset>
        <van-field v-model="text" placeholder="請輸入提幣數量" />
      </van-cell-group>
      <van-button style="background-color: #AC76FA;color: #fff;font-weight: bold;" size="large" class="butsty" @click="showPopup">確認</van-button>
    </div>
  </div>
  <!--FAQ彈框開始 -->
  <div v-if="show" style="margin-top: -10%;position: absolute;margin-left: 15%;width: 100%;" >
   <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;"/>
   <div style="position: absolute;font-size: 0.4rem;width: 60%;margin-top: -77%;margin-left: 5%;">
     <h2 style="margin-left: 30%;">通道類型</h2>
        <input style="margin-top: 5%;" type="radio" id="option1" name="chain_type"  value="1" v-model="chain_type">
          <label style="margin-left: 5%;font-weight: bold;" for="option1">BEP-20</label><br>

          <input style="margin-top: 5%;" type="radio" id="option2" name="chain_type"  value="2" v-model="chain_type" >
          <label style="margin-left: 5%;font-weight: bold;" for="option2">Roc-20</label><br>
   </div>
   <div @click="tixian" style="border-radius: 10px;background-color: #B791FA;width: 20%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-top: -11%;position: absolute;margin-left: 8%;">確認</div>
       <div @click="closePopup" style="border-radius: 10px;background-color: #B791FA;width: 30%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 33%;margin-top: -11%;position: absolute;">考慮一會</div>
  </div>
  <!--FAQ彈框開始 -->
</template>

<script setup>
import { ref, reactive } from 'vue';
import { detailed,AssetWithdrawal,getUserInfo } from "@/utils/api.js";
import { usdtabi } from '@/utils/abi.js'
import Web3 from "@/utils/web3.min.js";
import { useRouter } from "vue-router";
import { precision } from '@/utils/precision.js';
import { Toast } from 'vant';
import { Notify } from 'vant';

const chain_type = ref()
const show = ref(false)
const page = ref(1)
const page_size = ref(50)
const detailedlist = ref([])
const time = ref([])
const times = ref([])
const text = ref()
const onClickLeft = () => history.back();
const router = useRouter();
const add = ref('')
const userinfo = ref();
const userbalance = ref();
   // 获取用户信息
    async function getuser() {
     getUserInfo().then((res) => {
       userinfo.value =  res.data.user;
       // console.log(userinfo);
      let usdt = 1;
      let roc = 2;
      const type = router.currentRoute.value.query.amount == 1 ? usdt : roc
      // console.log(type);
      if (type === 1 ){
       userbalance.value = userinfo.value.money;
      }else{
        userbalance.value = userinfo.value.personal_money;
      }
     });


    }
getuser();



function showPopup(){
    show.value = true;
}

function closePopup(){
    show.value = false;
}

async function tixian() {
      let usdt = 1;
      let roc = 2;
      const type = router.currentRoute.value.query.amount == 1 ? usdt : roc
      if (chain_type.value == 1 && router.currentRoute.value.query.amount == 2){
        Toast({
          message: '維護中....',
          icon: 'fail',
          className: 'noticeWidth'
        });
        return
      }else {
        let form = {
          money: text.value,
          type: type,
          chain_type:chain_type.value,
        }
          AssetWithdrawal(form).then((res) => {
            Toast(res.message)
            onClickLeft()
          })
      }

}
</script>

<style lang="scss" scoped>
  .withdraw{
     overflow: hidden;
  }
input[type="radio"]:checked::after{
  background: #000;
  }
.van-nav-bar__content {
  height: 1rem !important;
}

.butsty {
  height: 80px;
  font-size: 30px;
  padding: 30px;
  border-radius: 20px;

}

.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}

::v-deep .van-cell {
  padding: 50px !important;
}

::v-deep .van-cell-group--inset {
  border-radius: 50px !important;
}

::v-deep .van-nav-bar__title {
  font-weight: bold !important;
  font-size: 30px !important;
  height: 80px;
}

::v-deep .van-nav-bar__content {
  padding-top: 30px;
  height: 80px;
  // margin: auto;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
  color: #000;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

.van-hairline--bottom:after {
  border-bottom-width: 0 !important;
}

.van-collapse-item {
  border: 1px red solid;
}

.van-popup {
  // position: absolute !important;
}

.van-dropdown-menu__title {
  line-height: normal !important;
}

.van-dropdown-menu__bar {
  height: 60px !important;
}

.van-nav-bar__left {
  padding: 0rem 0.3rem 0rem 0.3rem !important;
}

.van-ellipsis {
  font-weight: bold !important;
  overflow: visible;
}
</style>
